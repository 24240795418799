body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #F5F5F5;
}

.bold {
    font-weight: bold;
}

.m8 {
    margin: 8px;
}

.m10 {
    margin: 10px;
}

.mt8 {
    margin-top: 8px !important;
}

.mt10 {
    margin-top: 10px;
}

.mt16 {
    margin-top: 16px !important;
}

.mt20 {
    margin-top: 20px;
}

.mb8 {
    margin-bottom: 8px !important;
}

.mb10 {
    margin-bottom: 10px;
}

.mb16 {
    margin-bottom: 16px !important;
}

.ml8 {
    margin-left: 8px !important;
}

.ml10 {
    margin-left: 10px;
}

.mr10 {
    margin-right: 10px;
}

.center {
    text-align: center;
}

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.paper {
    width: 80vw;
    padding: 32px;
    max-width: 500px;
    background-color: #fff;
}

.paperText {
    width: 80vw;
    max-width: 500px;
    background-color: transparent;
    font-size: 0.825rem;
}

.h100 {
    height: 100vh;
}

.h80 {
    height: 80%;
}

.w100 {
    width: 100%;
}

.noUnderline {
    text-decoration: none;
}

.p10 {
    padding: 10px;
}

.p5 {
    padding: 5px;
}

.pt10 {
    padding-top: 10px
}

.pt50 {
    padding-top: 50px;
}

.pb10 {
    padding-bottom: 10px;
}

.pr10 {
    padding-right: 10px;
}

.pl10 {
    padding-left: 10px;
}

.br14 {
    border-radius: 14px;
}

.larger {
    font-size: larger;
}

.fs24 {
    font-size: 24px;
}

.flex-left-right {
    display: flex;
    justify-content: space-between;
}

.border-round {
    border: 10px solid !important;
    border-radius: 20px !important;
}

.border-round-thin {
    border: 5px solid;
    border-radius: 20px;
}

.kep-login-facebook.small {
    border-radius: 10px !important;
}

.win-container {
    width: 90vw;
    margin-top: 30px;
    max-width: 450px;
    background-color: #fff;
    border-radius: 18px;
}

.v-centering {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h-centering {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.centering {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.btn {
    border-bottom: solid 3px;
    border-radius: 5px !important;
    text-decoration: none !important;
    color: #fff !important;
}

.btn:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btn:disabled {
    transform: translateY(3px) !important;
    background-color: lightgray !important;
    border-bottom: none !important;
}

.btnSize {
    width: 100px !important;
    height: 35px !important;
}

.btnSizeDiscard {
    min-width: 100px !important;
    height: 35px !important;
}

.btnPrimary {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    border-bottom: solid 3px #79b369 !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnPrimary:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnPrimary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

.btnLogin {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    text-decoration: none !important;
    border-radius: 10px !important;
    height: 44px !important;
    min-width: 100px !important;
}

.btnLogin:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnLogin:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

.group-password {
    display: flex;
    align-items: flex-start;
}

.group-password div {
    border-radius: 10px;
}

.group-password input {
    padding: 14px;
}

.btnSecondary {
    color: #fff !important;
    background-color: #afafaf !important;
    border-bottom: solid 3px #9a9a9a !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnSecondary:active {
    transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnSecondary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

.account_avatar_div img {
    border-radius: 50px;
    background-color: white;
    border: solid black 3px;
}

.error {
    color: red !important;
}

.bgWhite {
    background-color: white !important;
}

.center-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.customInput {
    margin: 8px 0 !important;
}

.customInput input:-webkit-autofill,
.customInput input:-webkit-autofill:hover,
.customInput input:-webkit-autofill:focus,
.customInput input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.lineText {
    position: relative;
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.lineText::after {
    position: absolute;
    top: 50%;
    height: 2px;
    background: #ECECEC;
    content: "";
    width: 38%;
    left: 62%;
    margin-top: -1px;
}

.lineText::before {
    position: absolute;
    top: 50%;
    height: 2px;
    background: #ECECEC;
    content: "";
    width: 38%;
    left: 0;
    margin-top: -1px;
}

.ContactMentoroid {

    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;

}

.ContactMentoroid img {
    width: 100px;
    margin-bottom: 10px;
}

.ContactMentoroid-title {
    font-size: 21px;
    font-weight: bold;
    text-align: center;
}

.ContactMentoroid-subTitle {
    text-align: center;
    font-size: 13px;
}

.bg-gray {
    background-color: #f1f1f1;
}

.btn-forgot-password {
    margin: 20px 0;
    text-align: center;
}

.mfa-dialog .MuiPaper-root {
    border: 5px solid;
    border-radius: 5px;
}

.mfa-dialog .MuiDialogTitle-root {
    text-align: center;
    font-weight: bold;
}

.mfa-dialog .MuiDialogContent-root p {
    text-align: center;
    font-weight: bold;
    font-size: 15px;
}

@media (max-width: 575px) {
    .ContactMentoroid-title {
        font-size: 18px;

    }

    .mobileCenter {
        flex-direction: column;
        align-items: center;
    }

    .btn-forgot-password {
        margin: 10px 0;
    }
}
