.btn_show {
    display: block !important;
}
.btn_hide {
    display: none !important;
}
[class*="MuiExpansionPanel-root"]:before {
    height: 0px !important;
}

@media (max-width: 575px){
    [class*="MuiExpansionPanelSummary-content"] {
        display: block;
    }
}
.paginate ul li{
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #d3d3d3;
    text-align: center;
    line-height: 24px;
    border-radius: 12px;
    margin: 0 3px;
}
.paginate a {
    text-decoration: none;
    color: black;
}
